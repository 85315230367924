import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Helmut from "../components/helmut"
import Instagram from "../components/instagram"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  //fix rehydration bugs?
  //https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect( ()=> {
    setHasMounted(true);
  },[]);

  if(!hasMounted)
  {
    return null;
  }

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Helmut title="Microblog" />
        <Bio />
        <p>
          No posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Helmut title="Microblog" />
      <div>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          //const postwithlinebreaks = post.html.replace(new RegExp('\n','g'),'\n<br class="flatbreak">');
          const postwithlinebreaks = post.html;

          return (
            <div key={post.fields.slug} className="blog-post">
                  <h1 className="blog-post-header">
                    <Link to={post.fields.slug} itemProp="url">
                      <span>{title}</span>
                    </Link>
                  </h1>
                  <div className="post-date">{post.frontmatter.date}</div>
                
                  <Instagram>{post.frontmatter.ig}</Instagram>
                  <div
                    className="articleBody"
                    dangerouslySetInnerHTML={{ __html: postwithlinebreaks }}
                  />
              <hr className="blog-separator" />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark
    (
      sort: { fields: [frontmatter___date], order: DESC }
    ) 
    {
      nodes {
        html
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          ig
        }
      }
    }
  }
`
